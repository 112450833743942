export const packageId = 5 as number;
export const packageInfo = 'com.sambad.99lotto';
export const defaultChannel = 'indusbet';
export const disabledPhoneCode = true;
export const apiSendPhoneCode = false;
export const defaultPhoneCode = '91';
export const homePage = 'Index';
export const defaultApkPath = 'indusbet';
export const defaultApkName = 'indusbet.apk';
export const defaultApkPrefix = 'indusbet-';
export const gameNavigateVersion = 1;
